import styled from "styled-components";
import { TABLET_MAX_WIDTH } from "@constants";

export const StyledWhyChooseSectionWrapper = styled.div`
    & .benefits-section {
      margin-bottom: 0;
    }

    & .benefits-section-items {
      grid-template-rows: none;
    }

    & .benefits-card {
      margin-bottom: 2.5rem;
      height: 21rem;

      &__icon {
        svg {
          width: 5rem;
          height: 5rem;
        }
      }

      @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        height: fit-content;
      }
    }

    & .benefits-section-items :nth-child(4) {
      margin-bottom: 0;
    }
`;
