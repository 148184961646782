import React from "react";
import loadable from "@loadable/component";
import { 
    WHY_CHOOSE_IMPRESSIT_DESIGN_ITEMS, 
    WHY_CHOOSE_IMPRESSIT_HEADING 
} from "../../constants";
import { BenefitListContainer } from "./styled";

const BenefitsFlippableListSection = loadable(() => import("@common/CommonSections/BenefitsFlippableListSection"));

const WhyChooseSection = () => (
    <BenefitListContainer>
        <BenefitsFlippableListSection
            heading={WHY_CHOOSE_IMPRESSIT_HEADING}
            configuration={WHY_CHOOSE_IMPRESSIT_DESIGN_ITEMS}
        />
    </BenefitListContainer>
);

export default WhyChooseSection;
