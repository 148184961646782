import firstBenefit from "@images/benefit1.svg";
import thirdBenefit from "@images/benefit3.svg";
import hat from "@images/hat.svg";
import tax from "@images/tax.svg";

export const WHY_CHOOSE_IMPRESSIT_WEB_ITEMS = [
    {
        title: "Commitment",
        icon: firstBenefit,
        description: `
            As our team accompanies you at every stage of the web app development process, 
            from the initial meeting to the final release, you can be sure that we will 
            bring your business idea to reality.
        `,
    },
    {
        title: "Time & Costs Savings",
        icon: tax,
        description: `
            Сlear and precise plan created during the discovery phase combined with the knowledge 
            and experience of our team guarantee that you will avoid delays and unexpected expenses.
        `,
    },
    {
        title: "Reduced Risks",
        icon: thirdBenefit,
        description: `
            Working closely with you as your web application development partner, we are able to foresee 
            possible issues and avoid them. Hire web programmer with Impressit, and if unexpected obstacles 
            do arise on the way to success, our expert team will always be there to assist. 
        `,
    },
    {
        title: "Experience",
        icon: hat,
        description: `
            We work only with professionals that are confident in their expertness so they will share it with
            you and answer any of your questions related to the web app development process.
        `,
    },
];