import React from "react"
import loadable from "@loadable/component"
import { WHY_CHOOSE_IMPRESSIT_HEADING } from "../../../constants"
import { WHY_CHOOSE_IMPRESSIT_MOBILE_ITEMS } from "../../constants"
import { StyledWhyChooseSectionWrapper } from "../../../styled"

const BenefitsFlippableListSection = loadable(() => import("@common/CommonSections/BenefitsFlippableListSection"));

const WhyChooseSection = () => (
  <StyledWhyChooseSectionWrapper>
    <BenefitsFlippableListSection
      configuration={WHY_CHOOSE_IMPRESSIT_MOBILE_ITEMS}
      heading={WHY_CHOOSE_IMPRESSIT_HEADING}
    />
  </StyledWhyChooseSectionWrapper>
)
export default WhyChooseSection;
